import Blognav from "./Nav";
import "./Nav.css";

const Header = () => {
  return (
    <div className="back-color">
      <div className="container">
        <Blognav />
      </div>
    </div>
  );
};
//sticky-top
export default Header;
