import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import "../../App.css";

import "./notes.css";
import { GetJots, CreateJot, DeleteJot, UpdateJot } from "../../actions/jot";
import { useNavigate } from "react-router-dom";

const Note = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [notes, setNotes] = useState([]);
  const [reload, setReload] = useState(false);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selectedNote, setSelectedNote] = useState(null);

  const handleAddNote = (event) => {
    event.preventDefault();
    // console.log(title);
    // console.log(content);
    const newNote = {
      title: title,
      content: content,
    };

    CreateJot(newNote)
      .then((res) => {
        alert.success(`${res} succesfully created`);
      })
      .catch((error) => {
        alert.error(`issue: ${error}`);
      });

    setTitle("");
    setContent("");
    navigate("/notes");
  };

  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setTitle(note.title);
    setContent(note.content);
  };

  const handleUpdateNote = (event) => {
    event.preventDefault();
    if (!selectedNote) {
      return;
    }
    const updatedNote = {
      id: selectedNote.jot_id,
      title: title,
      content: content,
    };

    UpdateJot(updatedNote)
      .then((res) => {
        alert.success(`${res} succesfully updated`);
      })
      .catch((error) => {
        alert.error(`issue: ${error}`);
      });
    //setReload(!reload);
    setTitle("");
    setContent("");
    setSelectedNote(null);
    navigate("/");
  };

  const handleCancel = () => {
    setTitle("");
    setContent("");
    setSelectedNote(null);
  };

  const handleDeleteNote = (event, NoteId) => {
    event.stopPropagation();
    DeleteJot(NoteId)
      .then((res) => {
        alert.success(`Success with code: ${res}`);
      })
      .catch((error) => {
        console.log(NoteId);
        alert.error(`issue: ${error}`);
      });
    setReload(!reload);
  };

  useEffect(() => {
    //call action
    GetJots()
      .then((res) => {
        //update notes
        setNotes(res);
      })
      .catch((error) => {
        setNotes([{ title: "Error", content: `no data available! ${error}` }]);
      });
  }, []);

  return (
    <div className="app-container mt-2">
      <form
        className="note-form"
        onSubmit={(event) =>
          selectedNote ? handleUpdateNote(event) : handleAddNote(event)
        }
      >
        <input
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          placeholder="Enter Title"
          required
        />
        <textarea
          value={content}
          onChange={(event) => setContent(event.target.value)}
          placeholder="Content"
          rows={10}
          required
        />
        {selectedNote ? (
          <div className="edit-buttons">
            <button type="Submit">Save</button>
            <button onClick={handleCancel}>Cancel</button>
          </div>
        ) : (
          <button type="Submit">Add Note</button>
        )}
      </form>
      <div className="notes-grid">
        {notes.map((note) => (
          <div className="note-item" onClick={() => handleNoteClick(note)}>
            <div className="note-header">
              <button onClick={(event) => handleDeleteNote(event, note.jot_id)}>
                X
              </button>
            </div>
            <h2>{note.title}</h2>
            <p>{note.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Note;
