import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { CreateImageAction } from "../../actions/ImageMgt";

const CreateImage = () => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [filename, setFileName] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageFilePreview, setImageFilePreview] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    
    //const fileLists = document.getElementById("UploadFile").files[0];
    //const fileListUrl = URL.createObjectURL(fileLists);
    //const fileReader = createReadStream(fileLists);
    //console.log(fileListUrl);
    const newImage = {
      filename: filename,
      FileUrl: imageFile,
    };
    //console.log(newImage);

    CreateImageAction(newImage)
      .then((res) => {
        alert.success(`Success: ${res}`);
      })
      .catch((error) => {
        alert.error(`issue: ${error}`);
      });

    setFileName("");
    setImageFile("");
    //navigate("/image");
  };

  const handlecancel = () => {
    setFileName("");
    //setImageFile("");
  };

  const handleFileChange = (e) => {
    //setImageFile(e.target.files[0]);
    if (e.target.name === "UploadFile") {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          //setImagePreview(reader.result);
          const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
          setImageFile(base64String);
          setImageFilePreview(reader.result);
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    } else {
      alert.error('image upload issue!');
      return;
    }
  };

  return (
    <div className="container">
      <h2>Create Image</h2>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label for="filename">filename:</label>
            <input
              type="text"
              name="filename"
              className="form-control"
              id="filename"
              value={filename}
              onChange={(e) => setFileName(e.target.value)}
              required
            ></input>
          </div>
          <div className="form-group">
            <label for="UploadFile">Upload</label>
            <input 
                id="UploadFile"
                name="UploadFile" 
                className="form-control-file" 
                type="file" 
                accept="image/png, image/jpeg"
                onChange={handleFileChange}>
            </input>
          </div>
          <div className="row mt-2">
            <div className="col d-flex justify-content-center">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-danger"
                onClick={handlecancel}
              >
                Cancel
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <a className="btn btn-primary" href={"/image"}>
                Back
              </a>
            </div>
          </div>
        </form>
      </div>
    <img                            
        alt="loading..."
        className="img-fluid"
        src={imageFilePreview}
    />
    </div>
  );
};

export default CreateImage;
