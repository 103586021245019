import axios from "axios";

export const GetJots = async () => {
    try{
        const data = await axios.get('/api/jot/jots');
        const jsondataObj = data['data'].data[0];
        return jsondataObj;
    }catch(error){
        throw new Error(error);
    }
}

export const CreateJot = async (jot) => {
    try{
        const data = await axios.post('/api/jot/create',
            {title: jot.title, content: jot.content}
        );
        const jsondataObj = data['data'].data[0][0].title;
        return jsondataObj;
    }catch(error){
        throw new Error(error);
    }
}

export const UpdateJot = async (jot) => {
    try{
        const data = await axios.put(`/api/jot/update/${jot.id}`,
            {title: jot.title, content: jot.content}
        );
        const jsondataObj = data['data'].data[0][0].title;
        return jsondataObj;
    }catch(error){
        throw new Error(error);
    }
}

export const DeleteJot = async (id) => {
    try{
        const data = await axios.delete(`/api/jot/delete/${id}`);
        return data.status;
    }catch(error){
        throw new Error(error);
    }
}

//Oops! we ran into an issue: