import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { PredictFoundation } from "../../actions/Foundation";

const PredictFoundationsafety = () => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [safe, setSafe] = useState("waiting for image...");
  const [imageFile, setImageFile] = useState("");
  const [imageFilePreview, setImageFilePreview] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const newImage1 = {
      FileUrl: imageFile,
    };

    PredictFoundation(newImage1)
      .then((res) => {
        setSafe(res.prediction);
        alert.success(`Success: ${res.success}`);
      })
      .catch((error) => {
        alert.error(`issue: ${error}`);
      });
  };

  const handlecancel = () => {
    setSafe("waiting for image...");
    setImageFilePreview("");
    setImageFile("");
  };

  const handleFileChange = (e) => {
    //setImageFile(e.target.files[0]);
    if (e.target.name === "UploadFile") {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          //setImagePreview(reader.result);
          const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
          setImageFilePreview(reader.result);
          setImageFile(base64String);         
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    } else {
      alert.error('image upload issue!');
      return;
    }
  };

  const handleAlert=()=>{
    window.alert("Got alert!");
  }

  const handleConfirm=()=>{
    var isOkay = window.confirm("Are you sure you want to move?");
    if(isOkay){
      window.alert("Got alert!");
    }else{
      return;
    }
  }

  const handlePrompt=()=>{
    let sign = prompt("What's your sign?");
    window.alert(`Got alert! ${sign}`);
  }
  
  return (
    <div className="container">
      <h2>Foundation Safety Prediction</h2>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label for="safe">Prediction:</label>
            <input
              type="text"
              name="safe"
              className="form-control"
              id="safe"
              value={safe}
              onChange={(e) => setSafe(e.target.value)}
              readOnly
            ></input>
          </div>
          <div className="form-group">
            <label for="UploadFile">Upload</label>
            <input 
                id="UploadFile"
                name="UploadFile" 
                className="form-control-file" 
                type="file" 
                accept="image/png, image/jpeg"
                onChange={handleFileChange}>
            </input>
          </div>
          <div className="form-group">
            <label for="SelectType">Type</label>
            <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="SelectType">
              <option value="0">PNG</option>
              <option value="1">JPG</option>
              <option value="2">JPEG</option>
              <option value="3">NONE</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="terms">Qualities</label>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="Pro"/>
                    <label class="form-check-label" for="Pro">
                        Pro
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="Phone"/>
                    <label class="form-check-label" for="Phone">
                          Phone
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="RGB"/>
                    <label class="form-check-label" for="RGB">
                      RGB
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="Edited"/>
                    <label class="form-check-label" for="Edited">
                      Edited
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="Natural"/>
                    <label class="form-check-label" for="Natural">
                      Natural
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="Digital"/>
                    <label class="form-check-label" for="Digital">
                      Digital
                    </label>
                </div>
          </div>
          <div className="row mt-2">
            <div className="col d-flex justify-content-center">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-danger"
                onClick={handlecancel}
              >
                Cancel
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <a className="btn btn-primary" href={"/"}>
                Back
              </a>
            </div>
          </div>
        </form>
      </div>
    <img                            
        alt="loading..."
        className="img-fluid"
        src={imageFilePreview}
    />
    <div className="row mt-2">
            <div className="col d-flex justify-content-center">
              <button type="button" className="btn btn-primary" onClick={handleAlert}>
                Alert
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handlePrompt}
              >
                Prompt
              </button>
            </div>
          </div>
    </div>
  );
};

export default PredictFoundationsafety;
