import React, { Fragment, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { getUser, clearErrors } from "../../actions/users";
import "./user.css";
import Loader from "../Utils/loader.js";

const SpecificUser = () => {
  const params = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, user, error } = useSelector(
        (state) => state.specificUser
  );

  useEffect(() => {
    dispatch(getUser(params.id));
    if (error) {
      alert.error(error);
      dispatch(clearErrors);
      navigate("/users");
    }
  }, [dispatch, alert, error, params.id]);

  return (
    <Fragment>
        {loading ? <Loader /> : (
            <div className="container">
            <div className="row user-info">
                <div className="col-8 col-md-5">
                    <h2 className="mt-5 ml-5">{user.username}</h2>
                    <h4>Email</h4>
                    <p>{user.email}</p>
                    <h4>Last name</h4>
                    <p>{user.lastname}</p>
                    <Link to="/users" className="btn btn-primary btn-block mt-5">
                        Back
                    </Link>
                </div>
            </div>
            </div>
        )}
    </Fragment>
  );
};

export default SpecificUser;
