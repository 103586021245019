import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { GetSpecificImageUrl } from "../../actions/ImageMgt";

const ShowSpecificImage = () => {

    const params = useParams();
    const [logos, setLogos] = useState('loading...');

    useEffect(() => {
        //update blogs
        GetSpecificImageUrl(params.id)
        .then((res) => {
            //update notes
            setLogos(res);
        })
        .catch((error) => {
            setLogos([error.message]);
        });
    }, []);

    return(
        <div className='container container-fluid'>
            <h2 mb-5>Image</h2>
            
            <div mb-5>
                <a href='/home' block>
                    <img                            
                        alt="loading..."
                        className="img-fluid"
                        src={logos}
                    />
                </a>
            </div>
                       
        </div>
    )
}

export default ShowSpecificImage;