import axios from "axios";

export const PredictFoundation = async (blobData1) => {
    try{
        console.log(blobData1);
        const data = await axios.post('/api/ai/predict',
            blobData1
            //{headers: {'Content-Type': 'multipart/form-data'}},
        );
        const jsondataObj = data['data'];
        return jsondataObj;
    }catch(error){
        throw new Error(error);
    }
}