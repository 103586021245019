import {ALL_BLOG_REQUEST, ALL_BLOG_SUCCESS, ALL_BLOG_FAIL, CLEAR_ERRORS} 
from "../contants/blog";

export const allBlogs = (state = { blogs: [] }, action) => {
    switch (action.type) {
      case ALL_BLOG_REQUEST:
        return {
          ...state,
          loading: true,
          blogs: [],
        };
      case ALL_BLOG_SUCCESS:
        return {
          ...state,
          loading: false,
          blogs: action.payload          
        };
      case ALL_BLOG_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
};