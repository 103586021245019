import axios from "axios";

export const GetImageUrls = async () => {
    try{
        const data = await axios.get('/api/images');
        const jsondataObj = data['data'].data;
        return jsondataObj;
    }catch(error){
        throw new Error(error);
    }
}

export const GetSpecificImageUrl = async (nom) => {
    try{
        const data = await axios.get(`/api/images/${nom}`);
        const jsondataObj = data['data'].data;
        return jsondataObj;
    }catch(error){
        throw new Error(error);
    }
}

export const CreateImageAction = async (blobData1) => {
    try{
        //console.log(blobData1);
        const data = await axios.post('/api/images/create',
            blobData1
            //{headers: {'Content-Type': 'multipart/form-data'}},
        );
        const jsondataObj = data['data'].success;
        return jsondataObj;
    }catch(error){
        throw new Error(error);
    }
}