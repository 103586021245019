import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { CreateBlogAction } from "../../actions/blog";

const CreateBlog = () => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");

  const [paragraphContent, setparagraphContent] = useState([]);
  const [paragraph, setParagraph] = useState({
    blog_id: "",
    index: "",
    content: "",
  });
  const [paragraphs, setParagraphs] = useState([]);
  const [selectedPara, setSelectedPara] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const newBlog = {
      title: title,
      author: author,
      summary: summary,
      content: content,
    };

    CreateBlogAction(newBlog)
      .then((res) => {
        alert.success(`${res.title} succesfully created`);
      })
      .catch((error) => {
        alert.error(`issue: ${error}`);
      });

    setTitle("");
    setAuthor("");
    setSummary("");
    setContent("");
    navigate("/blog");
  };

  const handlecancel = () => {
    setTitle("");
    setAuthor("");
    setSummary("");
    setContent("");
    setParagraphs([]);
  };

  const handleAddparagraph = () => {
    const np = paragraphs.length + 1;
    const newParagraph = { blog_id: "", index: np, content: "" };
    const newContent = "";
    setParagraphs([...paragraphs, newParagraph]);
    setparagraphContent([...paragraphContent, newContent]);
  };

  const handleSelectedparagragh = (para) => {
    setparagraphContent(para.content);
  };

  return (
    <div className="container">
      <h2>Create Blog article</h2>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="form-group" id="blogDivTitle">
            <label for="title">Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            ></input>
          </div>
          <div className="form-group">
            <label for="author">Author</label>
            <input
              type="text"
              className="form-control"
              id="author"
              placeholder="author name"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              required
            ></input>
          </div>
          <div className="form-group">
            <label for="summary">Summary</label>
            <textarea
              type="text"
              className="form-control"
              id="summary"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              required
              rows="5"
            ></textarea>
          </div>
          <div className="form-group">
            <label for="content">Content</label>
            <textarea
              type="text"
              className="form-control"
              id="content"
              placeholder="content here"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
              rows="15"
            ></textarea>
          </div>
          <div>
            <h3>Paragraphs</h3>
            {paragraphs.map((para) => (
              <div className="form-group">
                <label for={`paragraph${para.index}`}>
                  paragraph {para.index}
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id={`paragraph${para.index}`}
                  //onClick={()=>handleSelectedparagragh(para)}
                  value={paragraphContent[para.index]}
                  onChange={(e) => {
                    //setparagraphContent(e.target.value);
                    paragraphContent[para.index] = e.target.value;
                    setParagraph({
                      blog_id: para.blog_id,
                      index: para.index,
                      content: paragraphContent[para.index],
                    });
                  }}
                  required
                  rows="5"
                ></textarea>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleAddparagraph}
            >
              Add Paragraph
            </button>
          </div>
          <div className="row mt-2">
            <div className="col d-flex justify-content-center">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-danger"
                onClick={handlecancel}
              >
                Cancel
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <a className="btn btn-primary" href={"/blog"}>
                Back
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateBlog;
