import React, { Fragment, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { loginAction, clearErrors } from "../../actions/users";
import Loader from "../Utils/loader.js";

const Login = () => {
  const params = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, logedUser, error, isAutheticated } = useSelector(
        (state) => state.logedUser
  );
  const [username, setUsername]=useState("");
  const [matUni, setMatUni]=useState("");

  useEffect(() => {

    //dispatch(loginAction());

    if(isAutheticated) {
        navigate('/');
    }

    if (error) {
      //console.log(error);Login is required!
      if(error == "Login is required!"){
        dispatch(clearErrors);
        return;
      }
      alert.error(error);
      dispatch(clearErrors);
    }
  }, [dispatch, alert, error, isAutheticated, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(loginAction(username, matUni));
  };

  return (
    <Fragment>
        {loading ? <Loader /> : (
            <div className="container">
            <div className="row wrapper my-5">
                <div className="col-10 col-lg-5 mx-auto">
                    <form className="shadow-lg px-5 py-3" onSubmit={submitHandler}>
                        <h1 className="mb-3">Login</h1>
                        <div className="form-group">
                            <label htmlFor="username_field">Username</label>
                            <input
                                type="text"
                                id="username_field"
                                className="form-control"
                                value={username}
                                onChange={(e) => {
                                setUsername(e.target.value);
                                }}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="matUni_field">Password</label>
                            <input
                                type="password"
                                id="matUni_field"
                                className="form-control"
                                value={matUni}
                                onChange={(e) => {
                                    setMatUni(e.target.value);
                                }}
                                required
                            />
                        </div>

                        <div className="mx-auto">
                            <button
                                id="login_button"
                                type="submit"
                                className="btn btn-primary btn-block py-3 mx-auto my-2"
                                >
                                    LOGIN
                            </button>
                        </div>

                        <div className="row">
                            <div className="col">
                                <Link to="/user/password/forgot" className="float-right mb-4" style={{textDecoration:'none'}}>
                                    Forgot Password?
                                </Link>                            
                            </div>                      
                            <div className="col">
                                <Link to="/user/register" className="mt-4">
                                    Sign Up?
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
        )}
    </Fragment>
  );
};

export default Login;
