import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { GetImageUrls } from "../../actions/ImageMgt";

const ShowImage = () => {

    const params = useParams();
    const [logos, setLogos] = useState(['loading...']);

    useEffect(() => {
        //update blogs
        GetImageUrls()
        .then((res) => {
            //update notes
            setLogos(res);
        })
        .catch((error) => {
            setLogos([error.message]);
        });
    }, []);

    return(
        <div className='container container-fluid'>
            <div className="row mb-2">
                <h2 className="col">Images</h2>
                <div className="col d-flex flex-row-reverse">
                    <a className="btn btn-primary" href={"/image/create"}>
                        Create Image
                    </a>
                </div>
            </div>
            <div>
                {logos.map((logo) =>(
                    <div mb-5>
                        <a href={`/image/${logo.nom}`}>
                            <img                            
                                alt="loading..."
                                className="img-fluid"
                                src={logo.url}
                            />
                        </a>
                    </div>
                ))} 
            </div>           
        </div>
    )
}

export default ShowImage;