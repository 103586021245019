import React, { useEffect, useState } from "react";

import { GetBlog } from "../../actions/blog";
import { useParams } from "react-router-dom";

const BlogDetails = () => {
  const params = useParams();
  const [blog, setBlog] = useState({
    title: "loading...",
    content: "loading...",
  });

  useEffect(() => {
    //update blogs
    GetBlog(params.id)
      .then((res) => {
        //update notes
        setBlog(res);
      })
      .catch((error) => {
        setBlog([
          {
            title: "Error",
            summary: "none",
            content: `no data available! ${error}`,
          },
        ]);
      });
  }, []);

  return (
    <div className="container">
      <h2>{blog.title}</h2>
      <div>
        <p>{blog.content}</p>
      </div>
    </div>
  );
};

export default BlogDetails;
