import axios from "axios";
import {ALL_USER_REQUEST, ALL_USER_SUCCESS, ALL_USER_FAIL, CLEAR_ERRORS,
  SPECIFIC_USER_REQUEST, SPECIFIC_USER_SUCCESS, SPECIFIC_USER_FAIL,
  LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGIN_USER_FAIL,
  LOGED_IN_USER_REQUEST, LOGED_IN_USER_SUCCESS, LOGED_IN_USER_FAIL,
  LOGOUT_USER_SUCCESS, LOGOUT_USER_FAIL
}
from "../contants/users";

//get all users
export const getUsers = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_USER_REQUEST });
    const data = await axios.get("/api/users");
    const jsondataObj = data["data"].data;
    dispatch({ type: ALL_USER_SUCCESS, payload: jsondataObj });
    //return jsondataObj;
  } catch (error) {
    //throw new Error(error);
    dispatch({
        type: ALL_USER_FAIL,
        payload: error.response.data.message,
      });
  }
};
//get specific uset
export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: SPECIFIC_USER_REQUEST });
    const data = await axios.get(`/api/users/${id}`);
    const jsondataObj = data["data"].data[0];
    dispatch({ type: SPECIFIC_USER_SUCCESS, payload: jsondataObj });
    //return jsondataObj;
  } catch (error) {
    //throw new Error(error);
    dispatch({
        type: SPECIFIC_USER_FAIL,
        payload: error.response.data.message,
      });
  }
};
//handle login
export const loginAction = (username, mat_uni) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_USER_REQUEST });
    const config = { headers: { "content-type": "application/json" } };
    const { data } = await axios.post(
      "/api/users/login",
      { username, mat_uni },
      config
    );
    dispatch({ type: LOGIN_USER_SUCCESS, payload: data.data[0] });
  } catch (error) {
    dispatch({
      type: LOGIN_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

//get logged user {based on ccokie}
export const getLogedUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOGED_IN_USER_REQUEST });
    const data = await axios.get("/api/users/logeduser");
    const jsondataObj = data["data"].message;
    dispatch({ type: LOGED_IN_USER_SUCCESS, payload: jsondataObj });
    //return jsondataObj;
  } catch (error) {
    //throw new Error(error);
    dispatch({
        type: LOGED_IN_USER_FAIL,
        payload: error.response.data.message,
      });
  }
};

//logout user {based on ccokie}
export const logout = () => async (dispatch) => {
  try {
    const data = await axios.get("/api/users/logout");
    const jsondataObj = data["data"].message;
    dispatch({ type: LOGOUT_USER_SUCCESS, payload: jsondataObj });
    //return jsondataObj;
  } catch (error) {
    //throw new Error(error);
    dispatch({
        type: LOGOUT_USER_FAIL,
        payload: error.response.data.message,
      });
  }
};

//register user
export const registerAction = async (user) => {
  try {
    const data = await axios.post("/api/users/create", user);
    const jsondataObj = data["data"];
    return jsondataObj;
  } catch (error) {
    //throw new Error(error);
    return error;
  }
};

// clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
      type: CLEAR_ERRORS,
    });
};