import React, { Fragment } from "react";
import Loader from "../Utils/loader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./user.css";

const Profile = () => {
  const { loading, logedUser } = useSelector((state) => state.logedUser);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <h2 className="mt-5 ml-5">Profile</h2>
          <div className="row justify-content-around mt-5 user-info">
            <div className="col-12 col-md-3">
              <figure className="avatar avatar-profile">
                <img
                  className="rounded-circle img-fluid"
                  src={logedUser.avator ? logedUser.avator : "/images/default.jpg"}
                  alt={logedUser.username}
                />
              </figure>
              <Link
                to="/profile/update"
                id="edit_profile"
                className="btn btn-primary btn-block my-5"
              >
                Edit Profile
              </Link>
            </div>

            <div className="col-12 col-md-5">
              <h4>Full Name</h4>
              <p>{logedUser.firstname} {logedUser.lastname}</p>

              <h4>Email Address</h4>
              <p>{logedUser.email}</p>

              <h4>Role</h4>
              <p>{logedUser.role}</p>

              <h4>Joined On</h4>
              <p>{logedUser.createdAt}</p>

              <p><Link to="/users" className="btn btn-danger btn-block">
                Back
              </Link></p>

              <Link
                to="/password/update"
                className="btn btn-primary btn-block"
              >
                Change Password
              </Link>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Profile;
