import React from "react";
import "./Nav.css";

const Home = () => {
  return (
    <div>
      <img
        alt="ES"
        className="img-fluid width1 mb-5"
        src="/images/ES_landing.png"
      />
      <div className="container">
        <h2>Empowering Your Future with Smart Solutions</h2>

        <div className="borderP">
          <p pl-5>
            At Entirely Smart, we are dedicated to providing exceptional
            services that meet the evolving needs of both the construction and
            ICT sectors. Our team delivers cutting-edge solutions, ensuring that
            every project is managed with precision, efficiency, and the highest
            standards of quality. Whether it's expertly managing construction
            projects or offering advanced ICT tools and educational content, we
            are committed to exceeding expectations and building trust through
            reliable and innovative services.
          </p>
        </div>

        <div className="card-gap">
          <div class="card-deck row">
            <div class="card col">
              <div class="card-body">
                <h5 class="card-title">Note-taking Feature</h5>
                <p class="card-text">
                  Our online note-taking web application offers a simple,
                  intuitive interface for capturing and organizing thoughts
                  seamlessly. Designed for professionals, students, and anyone
                  needing quick access to their notes, it ensures easy retrieval
                  and enhances productivity with cloud-based storage and real-time
                  synchronization.
                </p>
              </div>
              <div class="card-footer">
                <a href="/notes">
                  <small class="text-muted">Learn more</small>
                </a>
              </div>
            </div>
            <div class="card col">
              <div class="card-body">
                <h5 class="card-title">Blog Feature</h5>
                <p class="card-text">
                  Our blog provides in-depth educational content on the latest
                  frameworks like Node.js and React, alongside tutorials on
                  industry-leading products such as Azure and Microsoft Dynamics.
                  Designed for learners and professionals, the blog is your go-to
                  resource for mastering cutting-edge technology and development
                  tools.
                </p>
              </div>
              <div class="card-footer">
                <a href="/blog">
                  <small class="text-muted">Learn more</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
