export const ALL_USER_REQUEST = "ALL_USER_REQUEST";
export const ALL_USER_SUCCESS = "ALL_USER_SUCCESS";
export const ALL_USER_FAIL = "ALL_USER_FAIL";

export const SPECIFIC_USER_REQUEST = "SPECIFIC_USER_REQUEST";
export const SPECIFIC_USER_SUCCESS = "SPECIFIC_USER_SUCCESS";
export const SPECIFIC_USER_FAIL = "SPECIFIC_USER_FAIL";

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const LOGED_IN_USER_REQUEST = "LOGED_IN_USER_REQUEST";
export const LOGED_IN_USER_SUCCESS = "LOGED_IN_USER_SUCCESS";
export const LOGED_IN_USER_FAIL = "LOGED_IN_USER_FAIL";

export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL";


export const CLEAR_ERRORS = "CLEAR_ERRORS";
