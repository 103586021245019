import axios from "axios";
import {ALL_BLOG_REQUEST, ALL_BLOG_SUCCESS, ALL_BLOG_FAIL, CLEAR_ERRORS} 
from "../contants/blog";

export const GetBlogs = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_BLOG_REQUEST });
    const data = await axios.get("/api/blog");
    const jsondataObj = data["data"].data;
    dispatch({ type: ALL_BLOG_SUCCESS, payload: jsondataObj });
    //return jsondataObj;
  } catch (error) {
    //throw new Error(error);
    dispatch({
      type: ALL_BLOG_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const GetBlog = async (id) => {
  try {
    const data = await axios.get(`/api/blog/${id}`);
    const jsondataObj = data["data"].data[0];
    return jsondataObj;
  } catch (error) {
    throw new Error(error);
  }
};

export const CreateBlogAction = async (blog) => {
  try {
    const data = await axios.post("/api/blog/create", {
      title: blog.title,
      author: blog.author,
      summary: blog.summary,
      content: blog.content,
    });
    const jsondataObj = data["data"].data[0];
    return jsondataObj;
  } catch (error) {
    throw new Error(error);
  }
};

export const DeleteBlog = async (id) => {
  try{
      const data = await axios.delete(`/api/blog/delete/${id}`);
      return data.status;
  }catch(error){
      return error;
      //throw new Error(error);
  }
}

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
