import React, {Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { GetBlogs, DeleteBlog, clearErrors } from "../../actions/blog";
import Loader from "../Utils/loader.js";

const BlogList = () => {
  //const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, blogs, error } = useSelector(
        (state) => state.blogsState
        );

  const handleBlogDelete = (Id) => {
    DeleteBlog(Id)
      .then((res) => {
        alert.success(`Success with code: ${res}`);
      })
      .catch((error) => {
        alert.error(error);
      });
    navigate("/blog");//onClick={handleBlogDelete(blog.blog_id)}
  };

  useEffect(() => {
    if(error){
      alert.error(error);
      dispatch(clearErrors);
      navigate("/user/login");
    }
    dispatch(GetBlogs());
  }, [dispatch,alert,error]);

  return (
    <Fragment>
        {loading ? <Loader/> : (
          <div className="container">
            <div className="row mb-2">
              <h2 className="col">Articles</h2>
              <div className="col d-flex flex-row-reverse">
                <a className="btn btn-primary" href={"/blog/create"}>
                  Create Article
                </a>
              </div>
            </div>
            <div>
              {blogs.map((blog) => (
                <div class="card mb-2">
                  <div class="card-header">{blog.title}</div>
                  <div class="card-body">
                    <h7 class="card-subtitle mb-2 text-muted">
                      {blog.author}, {blog.createdAt.substring(0, 10)}
                    </h7>
                    <p class="card-text">{blog.summary}</p>
                    <a href={`/blog/${blog.blog_id}`} class="btn btn-primary">
                      Read more...
                    </a>
                    <button type="button" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
    </Fragment>
  );
};

export default BlogList;
